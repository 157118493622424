import {getReactEnv} from "../helpers/env"

const getConfigs = () => {
    const env = getReactEnv('ENV')
    switch (env) {
        case 'local':
            return {
                API_URL: 'http://0.0.0.0:8081/v1',
                API_URL_v1: 'http://0.0.0.0:8081/v1',
                API_APP_URI: 'https://api-dev.moonfit.xyz/v1/',
                APP_URI: 'dev-event.moonfit.xyz'
            }
        case 'development':
            return {
                API_URL: 'https://raffle-game-dev-api.moonfit.xyz',
                API_URL_v1: 'https://api-dev.moonfit.xyz/v1/',
                API_APP_URI: 'https://api-dev.moonfit.xyz/v1/',
                APP_URI: 'dev-event.moonfit.xyz'
            }

        case 'staging':
            return {
                API_URL: 'https://raffle-game-dev-api.moonfit.xyz',
                API_URL_v1: 'https://api-dev.moonfit.xyz/v1/',
                API_APP_URI: 'https://api-dev.moonfit.xyz/v1/',
                APP_URI: 'dev-event.moonfit.xyz'
            }
        case 'production':
            return {
                API_URL: 'https://raffle-game-api.moonfit.xyz',
                API_URL_v1: 'https://api-prod.moonfit.xyz/v1/',
                API_APP_URI: 'https://api-prod.moonfit.xyz/v1/',
                APP_URI: 'event.moonfit.xyz'
            }
        default:
            return {
                API_URL: 'https://raffle-game-dev-api.moonfit.xyz',
                API_URL_v1: 'https://api-dev.moonfit.xyz/v1/',
                API_APP_URI: 'https://api-dev.moonfit.xyz/v1/',
                APP_URI: 'dev-event.moonfit.xyz'
            }
    }
}

export const COMMON_CONFIGS = getConfigs()
