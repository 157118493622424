import React, {useEffect, useState} from "react"
import "./styles.less"
import {Button, Col, Image, Row, Typography, message} from "antd"
import logo from "../../assets/images/logo.png"
import logo2 from "../../assets/images/logo2.png"
import logoDark from "../../assets/images/logo-dark.png"
import logoSummer2 from "../../assets/images/summer/logo2.png"
import SITE_MENU from "../../core/utils/constants/site-menu"
import {Link, NavLink} from "react-router-dom"
import {ReactComponent as IconWallet} from "@svgPath/wallet.svg"
import {ReactComponent as IconBars} from "@svgPath/bars.svg"
import classNames from "classnames"
import {useAuth} from "../../core/contexts/auth"
import {getShortAddress} from "../../core/utils/helpers/blockchain"
import MFModal from "../MFModal"
import {CHAIN_ID_MAPPING, EVM_WALLETS} from "../../core/utils/constants/blockchain"
import {isMobileOrTablet} from "../../core/utils/helpers/device"
import iconWallet from "@imgPath/wallet-address.png"
import DOMEventServices from "../../core/services/dom-event"
import Jdenticon from "../Jdenticon"
import {useLocation, useParams} from "react-router-dom"
import {getLocalStorage, LOCALSTORAGE_KEY} from "../../core/utils/helpers/storage"

const {Paragraph} = Typography

const Header = () => {
    const params = useParams()
    const [isTop, setIsTop] = useState(true)
    const [toggleMenu, setToggleMenu] = useState(false)
    const [winners, setWinners] = useState([])
    const [isWalletModalVisible, setIsWalletModalVisible] = useState(false)

    const {auth, walletExtKey, showConnectModal, onDisconnect, onAuthorizeMoreWallet} = useAuth()
    const {isConnected, user} = auth

    const showWalletModal = () => {
        setIsWalletModalVisible(true)
    }

    const hideWalletModal = () => {
        setIsWalletModalVisible(false)
    }

    const onShowMessage = () => {
        message.error({
            content: "Coming soon",
            className: "message-error",
        })
    }

    const renderAccountExtra = () => {
        if (!walletExtKey) return <div />
        const walletExt = EVM_WALLETS.find((w) => w.extensionName === walletExtKey)
        return (
            <Row justify="space-between">
                <div className={"mr-3 pr-2 inline-flex normal-case text-base"}>
                    <span className={"mr-2"}>Connected with</span>{" "}
                    <img src={walletExt.logo.src} alt={walletExt.logo.alt} width={25} />
                </div>
                {!isMobileOrTablet() && (
                    <div className="connect-more-wallets" onClick={onAuthorizeMoreWallet}>
                        Connect more wallets
                    </div>
                )}
            </Row>
        )
    }

    useEffect(() => {
        const handleScroll = () => {
            if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
                setIsTop(false)
            } else {
                setIsTop(true)
            }
        }

        window.addEventListener("scroll", handleScroll)

        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [])

    useEffect(() => {
        DOMEventServices.subscribe("openLoginModal", () => {
            showConnectModal()
        })

        return () => {
            DOMEventServices.unsubscribe("openLoginModal")
        }
    }, [])

    const {pathname} = useLocation()

    const renderIconByEvent = () => {
        let output = null
        switch (params.id) {
            case "valentine-event":
                output = <span>&hearts;</span>
                break
            case "christmas-event":
                output = <span>&#10052;</span>
                break
            case "summer-fitsnap-challenge":
                output = <span>&#x2600;</span>
                break
            default:
                break
        }
        return output
    }

    const getChristmasWinner = (history = []) => {
        let winners = []
        if (history && history.length) {
            history.forEach((item, index) => {
                if (["MFR", "MFG", "GLMR"].includes(item?.metadata?.type)) {
                    winners.push(
                        <a
                            key={`token_${index}`}
                            href={`https://moonscan.io/address/${item?.wallet_address}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Congratulations,{" "}
                            <span style={{color: "#4DFFF6"}}>{getShortAddress(item?.wallet_address, 4)}</span>, on
                            winning {item?.metadata?.value} {item?.metadata.type} {renderIconByEvent()}{" "}
                        </a>
                    )
                } else {
                    winners.push(
                        <a
                            key={`item_${index}`}
                            href={`https://moonscan.io/address/${item?.wallet_address}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Congratulations,{" "}
                            <span style={{color: "#4DFFF6"}}>{getShortAddress(item?.wallet_address, 4)}</span>, on
                            winning {item?.metadata.type} {renderIconByEvent()}{" "}
                        </a>
                    )
                }
            })
        }
        return winners
    }

    window.addEventListener("checkWinner", () => {
        const specialEventWinners = getLocalStorage(LOCALSTORAGE_KEY.SPECIAL_EVENT_WINNERS)
        if (specialEventWinners) {
            const winners = getChristmasWinner(JSON.parse(specialEventWinners))
            setWinners(winners)
        }
    })

    const _renderMarquee = () => {
        let output = (
            <div className="event-winner">
                <marquee className="scroll-text" scrollamount="8">
                    <a
                        href="https://moonscan.io/address/0xE0850282Fa00b3F07D5f5D5837AA9bFFc2A69f4C"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Congratulate
                        <span style={{color: "#4DFFF6"}}> 0xE085...9f4C </span>
                        on winning the 5th weekly raffle game.
                    </a>
                </marquee>
            </div>
        )
        if (pathname.includes("/special-event")) {
            output = (
                <div className={`event-winner ${params.id === "valentine-event" ? "valentine" : "christmas"}`}>
                    <marquee className="scroll-text" scrollamount="8">
                        {winners}
                    </marquee>
                </div>
            )
        }
        return output
    }

    const getHeaderClassName = () => {
        let className = ""
        switch (params.id) {
            case "valentine-event":
                className = "valentine"
                break
            case "summer-fitsnap-challenge":
                className = "summer-event"
                break
            case "moonfit-x-hashkey-did-challenge":
                className = "hashkey-did"
                break
            case "moonfit-x-starfish-finance-challenge":
                className = "starfish"
                break
            case "lunar-gaming-festival-thanksgiving-challenge":
                className = "lunar-event"
                break
            case "christmas-challenge":
                className="christmas-challenge"
                break
            default:
                break
        }
        return className
    }

    const getLogoByEvent = () => {
        let image = logo
        switch (params.id) {
            case "valentine-event":
                image = !isTop ? logo2 : logo
                break
            case "summer-fitsnap-challenge":
                image = !isTop ? logo : logoSummer2
                break
            case "moonfit-x-hashkey-did-challenge":
            case "moonfit-x-starfish-finance-challenge":
                image = !isTop ? logo : logoDark
                break
            default:
                break
        }
        return image
    }

    return (
        <div>
            {_renderMarquee()}
            <header className={classNames(`header`, {"is-top": isTop}, getHeaderClassName())}>
                <Row align="middle">
                    <Col>
                        <div className="site-logo">
                            <Link to="/">
                                <img src={getLogoByEvent()} alt="logo" />
                            </Link>
                        </div>
                    </Col>
                    <Col flex="auto" className="header__center">
                        <nav className="header__nav">
                            <ul className="header__menu">
                                {SITE_MENU.map((item, index) => {
                                    return (
                                        <li key={index} className="header__menu-item">
                                            <div className="inner">
                                                {item.comingSoon && (
                                                    <a className="canvas-menu__nav-link" onClick={onShowMessage}>
                                                        {item.title}
                                                    </a>
                                                )}
                                                {!item.comingSoon && item.external && (
                                                    <a
                                                        target="_blank"
                                                        href={item.url}
                                                        className="canvas-menu__nav-link"
                                                        rel="noreferrer"
                                                    >
                                                        {item.title}
                                                    </a>
                                                )}
                                                {!item.comingSoon && !item.external && (
                                                    <NavLink to={item.url} className="canvas-menu__nav-link">
                                                        {item.title}
                                                    </NavLink>
                                                )}
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </nav>
                    </Col>
                    <Col className="header__right">
                        <div className="header__cta">
                            <div className="header__cta-login">
                                {isConnected ? (
                                    <Button
                                        className="header__wallet-address d-flex align-items-center"
                                        onClick={showWalletModal}
                                    >
                                        {/* <Image src={iconWallet} preview={false} width={36} /> */}
                                        <Jdenticon size="36" value={user.account} />
                                        {getShortAddress(user.account, 6)}
                                    </Button>
                                ) : (
                                    <Button type="primary" icon={<IconWallet />} onClick={showConnectModal}>
                                        Connect wallet
                                    </Button>
                                )}
                            </div>

                            <Button
                                type="text"
                                className="header__btn-toggle"
                                onClick={() => {
                                    setToggleMenu(true)
                                    document.body.classList.add("toggle-menu")
                                }}
                            >
                                <IconBars />
                            </Button>
                        </div>
                    </Col>
                </Row>
            </header>

            <div className={classNames("canvas-menu", {"is-open": toggleMenu})}>
                <div className="canvas-menu__wrapper">
                    <div className="canvas-menu__logo">
                        <Link to="/">
                            <Image src={logo} alt="mobile-log" preview={false} />
                        </Link>
                    </div>
                    <div className="canvas-menu__nav">
                        <ul className="canvas-menu__items">
                            {SITE_MENU.map((item, index) => {
                                return (
                                    <li key={index} className="canvas-menu__item">
                                        {item.comingSoon && (
                                            <a className="canvas-menu__nav-link" onClick={onShowMessage}>
                                                {item.title}
                                            </a>
                                        )}
                                        {!item.comingSoon && item.external && (
                                            <a
                                                target="_blank"
                                                href={item.url}
                                                className="canvas-menu__nav-link"
                                                rel="noreferrer"
                                            >
                                                {item.title}
                                            </a>
                                        )}
                                        {!item.comingSoon && !item.external && (
                                            <NavLink to={item.url} className="canvas-menu__nav-link">
                                                {item.title}
                                            </NavLink>
                                        )}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <div className="canvas-menu__cta">
                        <div className="canvas-menu__cta-login">
                            {isConnected ? (
                                <Button className="header__wallet-address -mobile" onClick={showWalletModal} block>
                                    <Image src={iconWallet} preview={false} width={36} />
                                    {getShortAddress(user.account, 6)}
                                </Button>
                            ) : (
                                <Button
                                    type="primary"
                                    className="-primary-2"
                                    icon={<IconWallet />}
                                    onClick={showConnectModal}
                                    block
                                >
                                    Connect wallet
                                </Button>
                            )}
                        </div>
                    </div>
                </div>

                <div
                    className="canvas-menu__outside"
                    onClick={() => {
                        setToggleMenu(false)
                        document.body.classList.remove("toggle-menu")
                    }}
                />
            </div>

            <MFModal
                title={"Wallet Information"}
                visible={isWalletModalVisible}
                onCancel={hideWalletModal}
                footer={[
                    <div className={"flex w-full"} key={"account-modal-footer"}>
                        <button
                            type="button"
                            onClick={() => onDisconnect(() => setIsWalletModalVisible(false))}
                            className="w-1/2 button button-dark"
                        >
                            Disconnect
                        </button>
                        <button type="button" onClick={hideWalletModal} className="w-1/2 button button-secondary">
                            Done
                        </button>
                    </div>,
                ]}
            >
                <div>
                    <div className="flex flex-col modal-body-row">
                        <div className={"flex modal-body-row-title"}>Wallet address</div>
                        <Paragraph
                            className={"text-flex text-white"}
                            copyable={{text: user.account, format: "text/plain"}}
                        >
                            {getShortAddress(user.account, 12)}
                        </Paragraph>
                        {renderAccountExtra()}
                    </div>
                    <div className="flex flex-col modal-body-row mt-3">
                        <div className={"flex modal-body-row-title"}>Balance</div>
                        <div className={"flex text-white"}>{user.balance} (GLMR)</div>
                    </div>
                    <div className="flex flex-col modal-body-row mt-3">
                        <div className={"flex modal-body-row-title"}>MFG Balance</div>
                        <div className={"flex text-white"}>{user.mfgBalance ? user.mfgBalance : "Unknown"} (MFG)</div>
                    </div>
                    <div className="flex flex-col modal-body-row mt-3">
                        <div className={"flex modal-body-row-title"}>Network</div>
                        <div className={"flex text-white"}>{CHAIN_ID_MAPPING[user.chainId]}</div>
                    </div>
                </div>
            </MFModal>
        </div>
    )
}

export default Header

