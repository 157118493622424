import React, { useEffect, useState } from "react"
import { Col, Row } from "antd"
import Event from "../../../../components/Event"
import "./styles.less"
import Container from "../../../../components/Container"
import ApiService from "../../../../core/services/api"


const getEvents = () => {
    const endpoint = `/manager-event/find-event`
    if (!window[endpoint]) {
        window[endpoint] =  ApiService.makeRequest.get(endpoint)
    }
    return window[endpoint].then(r => {
        delete window[endpoint]

        return r
    })
}

let _events = []

let storage = localStorage.getItem('_events')
if (storage) {
    try {
        storage = JSON.parse(storage)
        if (Array.isArray(storage) && storage.length > 0 && storage[0].id && storage[0].title) {
            _events = storage
        }
    } catch (e) {

    }
}

const EventsList = ({ auth }) => {
    const [events, setEvents] = useState(_events)
    // eslint-disable-next-line
    const [loading, setLoading] = useState(false)
    const isHolder = auth?.user?.appUser?.isHolder

    useEffect(() => {
        async function fetchEvents() {
            try {
                setLoading(true)
                const res = await getEvents()
                setLoading(false)
                const newEvent = [...res?.data?.events]
                localStorage.setItem('_events', JSON.stringify(newEvent))
                setEvents(newEvent)
            } catch (e) {
                console.error(e)
            }
        }

        fetchEvents()
        // eslint-disable-next-line
    }, [])

//     useEffect(()=>{
// if(events.length){
//     const dataSaveToLocal = events.map(event=>{
//         return {slug:event?.slug,end:event?.end}
//     })
//     localStorage.setItem("EVENTS_STATUS",JSON.stringify(dataSaveToLocal))
// }
//     },[events])

    return (
        <section className="events">
            <Container>
                <h3 className="events__title" data-aos="slide-up">
                    {auth.isConnected ? "Campaigns" : "Campaigns"}
                </h3>
                <Row
                    gutter={[
                        { xs: 20, sm: 30 },
                        { xs: 20, sm: 30 },
                    ]}
                    justify="center"
                >
                    {events.map((event, index) => (
                        <Col xl={8} md={12} sm={12} key={event.id}>
                            <Event key={event.id} event={event} isHolder={isHolder} position={index} />
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    )
}

export default EventsList
