import React, { useEffect } from "react"
import router from "./router"
import { AuthProvider } from "./core/contexts/auth"
import { TokenBalanceProvider } from "./core/contexts/token-balance"
import AOS from "aos"
import { useLocation } from 'react-router-dom'
import routerWithoutProvider from "./routerWithoutProvider"

function App() {
    useEffect(() => {
        AOS.init({
            once: true,
            offset: 0
        })
    }, [])
    return (
        <AuthProvider>
            <ScrollTop>
                <TokenBalanceProvider>{router}</TokenBalanceProvider>
                <div>{routerWithoutProvider}</div>
            </ScrollTop>
        </AuthProvider>
    )
}

const ScrollTop = ({ children }) => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        const body = document.querySelector("body")
        let timer = null
        if (body && location.pathname.includes('summer-fitsnap-challenge')) {
            timer = setTimeout(() => {
                body.style.backgroundColor = "#f3d9b1"
            }, 1000)
        } else {
            body.style.backgroundColor = "#020722"
        }
        return () => {
            clearTimeout(timer)
        }
    }, [location]);
    return children
}

export default App
