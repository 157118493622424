import React, { useEffect } from "react"
import "./styles.less"
import Banner from "../../components/Banner"
import EventsList from "./components/EventsList"
// import TweetsList from "./components/TweetsList"
import Assets from "./components/Assets"
import {useAuth} from "../../core/contexts/auth"
import {useTokeBalance} from "../../core/contexts/token-balance"

const Home = () => {
    const {auth} = useAuth()
    const balance = useTokeBalance()
    const hasAssets = auth?.user?.nftBalance?.total > 0 || Object.values(balance).some((item) => Number(item) > 0)

    useEffect(() => {
        document.title = "MoonFit"
    }, [])

    return (
        <div className="home-page">
            <Banner isLoggedIn={auth?.isConnected} hasAssets={hasAssets} />
            {auth.isConnected && <Assets />}
            <EventsList auth={auth} />
            {/*<TweetsList />*/}
        </div>
    )
}

export default Home
