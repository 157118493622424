import React, { useCallback, useContext, useEffect, useReducer, useState } from "react"
import AuthService from "../services/auth"
import MFModal from "../../components/MFModal"
import { EVM_WALLETS, PROVIDER_NAME, SUPPORTED_NETWORKS, WALLET_CONNECT, WEB3_METHODS } from "../utils/constants/blockchain"
import { isMobileOrTablet } from "../utils/helpers/device"
import { useLocalStorage } from "../hooks/useLocalStorage"
import { getLocalStorage, LOCALSTORAGE_KEY, removeLocalStorage, setLocalStorage } from "../utils/helpers/storage"
import { detectProvider, getNFTBalance, getPersonalSignMessage, switchNetwork } from "../utils/helpers/blockchain"
import Web3 from "web3"
import WalletConnectProvider from "@walletconnect/web3-provider";
import WalletConnect from "@walletconnect/client"
import QRCodeModal from "@walletconnect/qrcode-modal"
import { COMMON_CONFIGS } from "../utils/configs/common"

const { APP_URI } = COMMON_CONFIGS

const AuthContext = React.createContext()

const INIT_AUTH = {
    // authorized: false,
    user: {},
    isConnected: false
}

function authReducer(state, action) {
    switch (action.type) {
        case 'CONNECT':
            return { user: action.user, isConnected: action.isConnected }
        case 'DISCONNECT':
            return INIT_AUTH
        default:
            return state
    }
}

const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, INIT_AUTH)
    const [isConnectModalVisible, setIsConnectModalVisible] = useState(false)
    const [isSwitchWalletModalVisible, setIsSwitchWalletModalVisible] = useState(false)
    const [walletExtKey, setWalletExtKey] = useLocalStorage(LOCALSTORAGE_KEY.WALLET_EXT)
    const [provider, setProvider] = useState(null)
    const [, setNetWork] = useLocalStorage(LOCALSTORAGE_KEY.NETWORK)
    const isMetaMaskBrowser = isMobileOrTablet() && !!window[PROVIDER_NAME.MetaMask]
    const [connector, setConnector] = useState(null)

    useEffect(() => {
        function checkConnectedWallet() {
            const userData = JSON.parse(getLocalStorage(LOCALSTORAGE_KEY.WALLET_ACCOUNT))
            if (userData != null) {
                dispatch({ type: 'CONNECT', user: userData, isConnected: true })
            }
        }
        checkConnectedWallet()
    }, [])

    const hideConnectModal = () => setIsConnectModalVisible(false)

    const onWalletSelect = async (wallet) => {
        const providerName = wallet.extensionName
        const isInstalled = window[providerName] && window[providerName][wallet.isSetGlobalString]
        if (isMobileOrTablet() && !isMetaMaskBrowser) {
            let deepLink = `dapp://${APP_URI}`
            if (providerName === "SubWallet") {
                deepLink = `subwallet://browser?url=${APP_URI}`
            }
            return window.location.href = deepLink
        }
        if (!isInstalled && wallet.needInstall) return window.open(wallet.installUrl)
        setWalletExtKey(providerName)
        await onConnect(providerName)
        document.body.classList.remove('toggle-menu')
        hideConnectModal()
        return true
    }

    const onConnectViaSubWallet = async () => {
        setWalletExtKey(PROVIDER_NAME.SubWallet)
        return await onConnect(PROVIDER_NAME.SubWallet)
    }

    const onAuthorizeNewWallet = async () => {
        const provider = await detectProvider(walletExtKey)
        await provider.request(WEB3_METHODS.requestPermissions)
        setIsSwitchWalletModalVisible(false)
        await retrieveCurrentWalletInfo(provider)
    }

    const saveWalletInfo = useCallback((user, currentBalance, ethBalance, account, chainId, nftBalance, jwt) => {
        const walletAccount = {
            appUser: user,
            account: account,
            balance: ethBalance,
            currentBalance,
            chainId: chainId,
            nftBalance
        }
        setLocalStorage(LOCALSTORAGE_KEY.WALLET_ACCOUNT, JSON.stringify(walletAccount)) // user persisted data
        setLocalStorage(LOCALSTORAGE_KEY.ACCESS_TOKEN, jwt) // user persisted data
        const userData = JSON.parse(getLocalStorage(LOCALSTORAGE_KEY.WALLET_ACCOUNT))
        dispatch({ type: 'CONNECT', user: userData, isConnected: true })

    }, [])

    const retrieveCurrentWalletInfo = useCallback(async (provider) => {
        try {
            const web3 = new Web3(provider)
            const walletAccount = await web3.eth.getAccounts()
            const account = walletAccount[0]
            const res = await handleLogin(provider, account)
            if (res) {
                const { data, signData } = res
                const { user, jwt } = data

                // Get on-chain information
                const currentBalance = await web3.eth.getBalance(account) // Get wallets balance
                const ethBalance = web3.utils.fromWei(currentBalance, 'ether')
                const chainId = await provider.request({ method: 'eth_chainId' })
                const nChainId = web3.utils.hexToNumber(chainId)
                const network = SUPPORTED_NETWORKS.filter(
                    (chain) => chain.chain_id === nChainId
                )[0]
                const nftBalance = await getNFTBalance(network.rpc_url, account)
                user.isHolder = nftBalance.total > 0
                setNetWork(network)
                saveWalletInfo(user, currentBalance, ethBalance, account, nChainId, nftBalance, jwt)
                return signData
            }
        } catch (e) {
            console.error(e)
        }
    }, [saveWalletInfo, setNetWork])

    const handleWalletChange = useCallback(async (wallets) => {
    //     const account = state?.user?.account
    //     if (!account) return false

    //     const provider = await detectProvider(walletExtKey)
    //     if (!provider) {
    //         console.log("HandleWalletChange: SubWallet is not installed")
    //         return false
    //     }
    //     if (wallets.length === 0) return true
    //     else if (wallets.length === 1) {
    //         // console.log(wallets.account, wallets[0])
    //         setIsSwitchWalletModalVisible(false)
    //         account !== wallets[0] && await retrieveCurrentWalletInfo(provider)
    //     } else {
    //         setIsSwitchWalletModalVisible(true)
    //     }
    }, [state.user, retrieveCurrentWalletInfo, detectProvider])

    useEffect(() => {
        walletExtKey && detectProvider(walletExtKey).then(async provider => {
            // await provider?.request(WEB3_METHODS.requestAccounts)
            setProvider(provider)
            // provider && provider?.on('accountsChanged', handleWalletChange)
            // provider?.on('chainChanged', () => {
            //     console.log('chainChanged')
            // })
        })
        // return () => provider?.removeListener('accountsChanged', handleWalletChange)
    }, [walletExtKey, handleWalletChange])

    useEffect(() => {
        walletExtKey && detectProvider(walletExtKey).then(async provider => {
            await provider?.request(WEB3_METHODS.requestAccounts)
            setProvider(provider)
            provider && provider?.on('accountsChanged', handleWalletChange)
            // provider?.on('chainChanged', () => {
            //     console.log('chainChanged')
            // })
        })
        return () => provider?.removeListener('accountsChanged', handleWalletChange)
    }, [provider, walletExtKey, detectProvider, handleWalletChange])

    const onConnect = async (providerNameParam = null) => {
        try {
            let provider
            if (providerNameParam === "walletConnect") {
                provider = new WalletConnectProvider({
                    rpc: {
                        1287: "https://rpc.api.moonbase.moonbeam.network"
                    }
                })
                await provider.enable();
            } else {
                provider = await detectProvider(providerNameParam || walletExtKey)
                if (!provider) {
                    console.log('Wallet extension is not installed')
                    return
                }
                setProvider(provider)
                await provider.request({ method: 'eth_requestAccounts' })
            }
            // setProvider(provider)
            await provider.request({ method: 'eth_requestAccounts' })
            await switchNetwork(provider)
            return await retrieveCurrentWalletInfo(provider)
        } catch (err) {
            console.log(
                'There was an error fetching your accounts. Make sure your SubWallet or MetaMask is configured correctly.', err
            )
        }
    }

    useEffect(() => {
        try {
            const connectWC = async (chainId, connectedAccount) => {
                const networkData = SUPPORTED_NETWORKS.filter(
                    (chain) => chain.chain_id === chainId
                )[0]

                if (networkData) {
                    const signMessage = `MoonFit:${connectedAccount}:${new Date().getTime()}`
                    const msgParams = [getPersonalSignMessage(signMessage), connectedAccount]
                    const signature = await connector.signPersonalMessage(msgParams);
                    const signData = {
                        moon_fit_msg: signMessage,
                        signature
                    }
                    const reqData = {
                        wallet_address: connectedAccount,
                        ...signData
                    }
                    const { data } = await AuthService.login(reqData)
                    const { user, jwt } = data
                    setNetWork(networkData)
                    const web3 = new Web3(networkData.rpc_url)

                    const currentBalance = await web3.eth.getBalance(connectedAccount) // Get wallets balance
                    const ethBalance = web3.utils.fromWei(currentBalance, 'ether')
                    const network = SUPPORTED_NETWORKS.filter(
                        (chain) => chain.chain_id === chainId
                    )[0]
                    const nftBalance = await getNFTBalance(network.rpc_url, connectedAccount)
                    user.isHolder = nftBalance.total > 0
                    setNetWork(network)
                    saveWalletInfo(user, currentBalance, ethBalance, connectedAccount, chainId, nftBalance, jwt)
                }
            }

            if (connector) {
                connector.on("connect", async (error, payload) => {
                    const { chainId, accounts } = payload.params[0]
                    await connectWC(chainId, accounts[0])
                    setIsConnectModalVisible(false)
                })

                connector.on("disconnect", async (error, payload) => {
                    if (error) {
                        throw error
                    }
                    await onDisconnect()
                })
            }
        } catch (e) {
            console.error(e)
        }
    }, [connector])

    useEffect(() => {
        const wc = getLocalStorage(LOCALSTORAGE_KEY.WC_CONNECTOR, null)
        if (wc && isMobileOrTablet()) {
            const connector = new WalletConnect({ session: JSON.parse(wc) })
            setConnector(connector)
        }
    }, [])

    const onWCConnect = async () => {
        const connector = new WalletConnect({
            bridge: "https://bridge.walletconnect.org",
            qrcodeModal: QRCodeModal,
            storageId: LOCALSTORAGE_KEY.WC_CONNECTOR,
            qrcodeModalOptions: { desktopLinks: [] }
        })
        setConnector(connector)

        if (connector.connected) {
            await connector.killSession()
        }
        // check if already connected
        if (!connector.connected) {
            // create new session
            await connector.createSession()
        }
    }

    const onDisconnect = async (callback = null) => {
        if (connector?.connected) {
            await connector.killSession()
            removeLocalStorage(LOCALSTORAGE_KEY.WC_CONNECTOR)
        }
        removeLocalStorage(LOCALSTORAGE_KEY.WALLET_ACCOUNT)
        removeLocalStorage(LOCALSTORAGE_KEY.NETWORK)
        removeLocalStorage(LOCALSTORAGE_KEY.ACCESS_TOKEN)
        setWalletExtKey(null)
        dispatch({ type: 'DISCONNECT' })
        callback && callback()
    }

    const handleLogin = async (provider, account) => {
        try {
            const signMessage = `MoonFit:${account}:${new Date().getTime()}`
            const signature = await provider.request({
                method: 'personal_sign',
                params: [getPersonalSignMessage(signMessage), account]
            })
            const signData = {
                moon_fit_msg: signMessage,
                signature
            }
            const reqData = {
                wallet_address: account,
                ...signData
            }
            const { data, success, message } = await AuthService.login(reqData)
            return { data, success, message, signData }
        } catch (e) {
            console.error(e)
            // alert(`Cannot login: ${e.message}`)
        }
    }

    const onAuthorizeMoreWallet = async () => {
        const provider = await detectProvider(walletExtKey)
        await provider.request(WEB3_METHODS.requestPermissions)
        await retrieveCurrentWalletInfo(provider)
    }

    const context = {
        auth: state,
        walletExtKey,
        showConnectModal: () => setIsConnectModalVisible(true),
        onDisconnect,
        onAuthorizeMoreWallet,
        onConnectViaSubWallet,
        provider,
    }

    return (
        <AuthContext.Provider value={context}>
            {children}
            <MFModal title={"Connect Wallet"}
                visible={isConnectModalVisible}
                centered={true}
                onCancel={() => setIsConnectModalVisible(false)}
                footer={false}
            >
                {
                    EVM_WALLETS.map((wallet, index) => {
                        const isInstalled = window[wallet.extensionName] && window[wallet.extensionName][wallet.isSetGlobalString]
                        const onClick = (e) => {
                            e.preventDefault()
                            window.open(wallet.installUrl)
                        }
                        const isVisible = isMobileOrTablet() ? wallet.isMobileSupport : true
                        return isVisible && (
                            <div key={index} className={'evm-wallet-item'}
                                onClick={() => onWalletSelect(wallet)}>
                                <div className={"wallet-logo"}>
                                    <img src={wallet.logo.src} alt={wallet.logo.alt} width={40} />
                                </div>
                                <div className="wallet-title">{wallet.title}</div>
                                {
                                    (!isInstalled && wallet.needInstall) && !isMobileOrTablet() && (
                                        <div className="wallet-install-btn h-link"
                                            onClick={onClick}>Install</div>
                                    )
                                }
                            </div>
                        )
                    })
                }
                {
                    (
                        <div className={'evm-wallet-item'}
                            onClick={onWCConnect}>
                            <div className={"wallet-logo"}>
                                <img src={WALLET_CONNECT.logo.src} alt={WALLET_CONNECT.logo.alt} width={40} />
                            </div>
                            <div className="wallet-title">{WALLET_CONNECT.title}</div>
                        </div>
                    )
                }
            </MFModal>
            <MFModal title={"Unauthorized Wallet"}
                visible={isSwitchWalletModalVisible}
                centered={true}
                onCancel={() => setIsSwitchWalletModalVisible(false)}
                footer={[
                    <div className={'flex flex-col'} key="unauthorized-wallet-modal-footer">
                        <div className={'w-full'}>
                            <button type="button"
                                onClick={onAuthorizeNewWallet}
                                className="w-full button button-primary">
                                Yes, let me authorize
                            </button>
                        </div>
                        <div className={'w-full mt-3'}>
                            <button type="button"
                                onClick={() => setIsSwitchWalletModalVisible(false)}
                                className="w-full button button-secondary">
                                No, I will switch to another one now
                            </button>
                        </div>
                    </div>
                ]}
            >
                <div className={'normal-case text-xl'}>
                    Your current wallet is not authorized to connect to MoonFit WebApp. Do you want to authorize this
                    wallet now?
                </div>
            </MFModal>
        </AuthContext.Provider>
    )
}

function useAuth() {
    const context = useContext(AuthContext)

    if (context === undefined) {
        throw new Error("useAuth must be used within a AuthProvider")
    }

    return context
}

export { AuthProvider, useAuth }
