import React from "react"
import { Outlet } from "react-router-dom"
import "./styles.less"
import Header from "../Header"
import Footer from "../Footer"

const Layout = () => {
    return (
        <div className="app">
            <div className="stars-container" data-firefly-total="50">
                <div className="stars-container__star"></div>
            </div>
            <Header />
            <main>
                <Outlet />
            </main>
            <Footer />
        </div>
    )
}

export default Layout
