import ApiService from "./api"
import TokenService from "./token"

const isUserLogin = () => {
    return true
}

const login = async (reqData) => {
    try {
        const {data: resp} = await ApiService.makeRequest.post("/api/users/login_wallet", reqData)
        return {
            success: true,
            data: resp,
        }
    } catch (e) {
        const {response} = e
        const message = response ? response.statusText : e.message || e
        const data = response ? response.data : ''
        return {
            success: false,
            message,
            data
        }
    }
}

const logout = () => {
    TokenService.clearTokens()
}

const AuthService = {
    logout,
    login,
    isUserLogin,
}

export default AuthService
