import React from "react"
import BannerNotLoggedIn from "./NotLoggedIn"
import "./styles.less"
import BannerUserAssets from "./UserAssets";
import BannerUserNoAssets from "./UserNoAssets";

const Banner = ({isLoggedIn = true, hasAssets = false}) => {
    const layout = !isLoggedIn ? "not-logged-in" : hasAssets ? "user-assets" : "user-no-assets"

    return (
        <div className={`banner is-${layout}`}>
            {
                !isLoggedIn ? <BannerNotLoggedIn /> : hasAssets ? <BannerUserAssets /> : <BannerUserNoAssets />
            }
        </div>
    )
}

export default Banner
