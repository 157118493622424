import React from "react"
import { Route, Routes } from "react-router-dom"
import Layout from "./components/Layout"
import ClanBattle from "./pages/ClanBattle"
import EventDetail from "./pages/Detail"
import Home from "./pages/Home"
import SpecialEvent from "./pages/SpecialEvent"

const router = (
    <Routes>
        <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/events/:id" element={<EventDetail />} />
            <Route path="/clan-battle/:id" element={<ClanBattle />} />
            <Route path="/special-event/:id" element={<SpecialEvent />} />
            <Route path="*" element={<Home />} />
        </Route>

    </Routes>
)

export default router
