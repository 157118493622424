export const AMOUNTS_MORE = [
    {
        label: '100',
        value: 100,
    },
    {
        label: '150',
        value: 150,
    },
    {
        label: '200',
        value: 200,
    },
    {
        label: '300',
        value: 300,
    },
    {
        label: '500',
        value: 500,
    }
]

export const BATTLE_STATUS = {
    RUNNING: 'running',
    BET_LOCKED : 'bet_locked',
    VERIFY: 'verify',
    CLAIM_REWARD: 'claim-reward'
}

